import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import constants from "../../../utils/Constants";


const PdfView = (props) => {
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    height: '750px',
                    width: '100%'
                }}
            >
                <Viewer fileUrl={constants.BACKEND_URL + "/"+props.filename} />
            </div>
        </Worker>
    )
}

export default PdfView