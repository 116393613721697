import {
    Box,
    Chip, Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import HttpServices from "../../../utils/HttpServices";
import dayjs from "dayjs";
import {
    Cancel,
    Draw, DriveFileRenameOutline,
    HourglassTop, RemoveRedEyeTwoTone, TextSnippet,
    ThumbDown,
    ThumbUp,
    Unpublished,
    Verified, WorkspacePremium,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {Timeline, TimelineEvent} from "@mailtop/horizontal-timeline";

const KadisPelayananPage = () => {
  const [pengajuan, setPengajuan] = React.useState([]);
  const [detailProgress, setDetailProgress] = React.useState(null)

  const { enqueueSnackbar } = useSnackbar();

  function GetPengajuan() {
    HttpServices.get("/transaksi/pengajuan-cuti").then((response) =>
      setPengajuan(response.data)
    );
  }
  const GetDetail = (id_cuti) => {
    setDetailProgress(pengajuan.find((v) => v.id_cuti === id_cuti))
  }

  function ApproveAction(id_cuti) {
    HttpServices.post("/transaksi/pengajuan-cuti/update-status", {
      id_cuti: id_cuti,
      status_dokumen: 3,
    })
      .then((response) => {
        enqueueSnackbar(
          "Draft Pengajuan Cuti Berhasil Di Approve / Verifikasi",
          {
            variant: "success",
            persist: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        GetPengajuan();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(
          "Pengajuan Draft Cuti Gagal Di Approve, Cobalah Beberapa Saat Lagi !!",
          {
            variant: "error",
            persist: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      });
  }

  function RejectAction(id_cuti) {
    HttpServices.post("/transaksi/pengajuan-cuti/update-status", {
      id_cuti: id_cuti,
      status_dokumen: -1,
    })
      .then((response) => {
        enqueueSnackbar(
          "Draft Pengajuan Cuti Berhasil Di Reject / Verifikasi",
          {
            variant: "success",
            persist: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        GetPengajuan();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(
          "Pengajuan Draft Cuti Gagal Di Reject, Cobalah Beberapa Saat Lagi !!",
          {
            variant: "error",
            persist: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      });
  }

  React.useEffect(() => {
    GetPengajuan();
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="overline">draft pengajuan cuti</Typography>
        <Box sx={{ height: 600, width: "100%" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                // Forced to use important since overriding inline styles
                maxHeight: "168px !important",
              },
            }}
            autoPageSize={25}
            rows={pengajuan}
            getRowId={(row) => row.id_cuti}
            columns={[
              {
                field: "action",
                headerName: "Action",
                width: 200,
                renderCell: (params) => (
                  <>
                  <Tooltip title="Lihat Timeline">
                    <IconButton
                      onClick={(e) => GetDetail(params.row.id_cuti)}
                      color="info"
                      >
                      <RemoveRedEyeTwoTone />
                    </IconButton>
                  </Tooltip>
                    <Tooltip title="Approve">
                      <IconButton
                        disabled={params.row.status_dokumen != 2}
                        onClick={(e) => ApproveAction(params.row.id_cuti)}
                        color="primary"
                      >
                        <ThumbUp />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject">
                      <IconButton
                        disabled={params.row.status_dokumen != 2}
                        onClick={(e) => RejectAction(params.row.id_cuti)}
                        color="error"
                      >
                        <ThumbDown />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              },
              {
                field: "createdAt",
                headerName: "Tanggal Draft Cuti",
                width: 200,
                valueGetter: (params) =>
                  dayjs(params.row.createdAt).format("DD/MM/YYYY"),
              },
              {
                field: "status_verifikasi",
                headerName: "Status",
                headerAlign: "left",
                align: "left",
                width: 200,
                renderCell: (params) => {
                  if (params.row.status_dokumen === 0) {
                    return (
                      <Tooltip title="Menunggu Verifikasi">
                        <IconButton color="primary">
                          <HourglassTop />
                        </IconButton>
                      </Tooltip>
                    );
                  } else if (params.row.status_dokumen === 1) {
                    return (
                      <Tooltip title={`Diverifikasi Oleh ${params.row.pemberi_verif.nama}`}>
                        <IconButton color="success">
                          <Verified />
                        </IconButton>
                      </Tooltip>
                      );
                  } else if (params.row.status_dokumen === -1) {
                    <Tooltip title="Ditolak">
                      <IconButton color="error">
                        <Unpublished />
                      </IconButton>
                    </Tooltip>;
                  } else if (params.row.status_dokumen === 2) {
                    return (
                      <Tooltip title={`Di Paraf Oleh ${params.row.pemaraf.nama}`}>
                        <IconButton color="info">
                          <Draw />
                        </IconButton>
                      </Tooltip>
                      );
                  } else if (params.row.status_dokumen === 3) {
                    return (
                      <Tooltip title={`Di Tandatangani Oleh ${params.row.penanda_tangan.nama}`}>
                        <IconButton color="info">
                          <Draw />
                        </IconButton>
                      </Tooltip>
                      );
                  }
                },
              },
              {
                field: "jenis_cuti",
                headerName: "Jenis Cuti",
                width: 200,
                valueGetter: (params) => {
                  if (params.row.jenis_cuti === "cuti_tahunan") {
                    return "Tahunan";
                  } else if (params.row.jenis_cuti === "cuti_alasan_penting") {
                    return "Alasan Penting";
                  } else {
                    return "Melahirkan";
                  }
                },
              },
              {
                field: "nama",
                headerName: "Atas Nama",
                width: 200,
                valueGetter: (params) => params.row.pegawai.nama,
              },
              {
                field: "tgl_mulai",
                headerName: "Tgl Mulai Cuti",
                width: 200,
                renderCell: (params) => dayjs(params.row.tgl_mulai).format("DD/MM/YYYY")
              },
              {
                field: "tgl_selesai",
                headerName: "Tgl Selesai Cuti",
                width: 200,
                renderCell: (params) => dayjs(params.row.tgl_selesai).format("DD/MM/YYYY")
              },
              {
                field: "durasi",
                headerName: "Durasi Cuti",
                width: 200,
                valueGetter: (params) => `${params.row.durasi} Hari`,
              },
              {
                  field: "atasan",
                  headerName: "Atasan Langsung",
                  width: 250,
                  renderCell: (params) => params.row.pemberi_verif.nama
                },
                {
                  field: "verif",
                  headerName: "Pemberi Verifikasi",
                  width: 250,
                  renderCell: (params) => "Yulia Azizah, SKM"
                },
              {
                field: "paraf",
                headerName: "Pemaraf",
                width: 250,
                renderCell: (params) => params.row.pemaraf == null ? null : params.row.pemaraf.nama
              },
              {
                field: "ttd",
                headerName: "Penanda Tangan",
                width: 250,
                renderCell: (params) => params.row.penanda_tangan.nama
              },
              {
                field: "alasan_pengajuan_cuti",
                headerName: "Keperluan/Alasan",
                width: 300,
                //   valueGetter: (params) => `${params.row.durasi} Hari`,
              },
              {
                field: "alamat_selama_cuti",
                headerName: "Alamat Selama Cuti",
                width: 600,
                //   valueGetter: (params) => `${params.row.durasi} Hari`,
              },
            ]}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {detailProgress !== null && (
          <Box sx={{ overflow: "auto", textAlign: "center", mt: 2 }}>
            <Typography variant="overline">
              Progress Timeline Pengajuan Cuti
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Timeline minEvents={3} placeholder>
              {detailProgress.timeline.sort((a, b) => a.verif_level - b.verif_level).map((progress) => {
                let title = "";
                let color = "";
                let icon;
                if (progress.verif_level === 0) {
                  title = "Di Ajukan";
                  color = "#87a2c7";
                  icon = TextSnippet;
                } else if (progress.verif_level === 1) {
                  title = "Diverifikasi";
                  color = "#394867";
                  icon = Verified;
                } else if (progress.verif_level === 2) {
                  title = "Di Paraf";
                  color = "#19A7CE";
                  icon = DriveFileRenameOutline;
                } else if (progress.verif_level === 3) {
                  title = "Di Tanda Tangani";
                  color = "#1B9C85";
                  icon = WorkspacePremium;
                } else if (progress.verif_level === -1) {
                  title = "Di Tolak";
                  color = "#7E1717";
                  icon = Cancel;
                }
                return (
                  <TimelineEvent
                    icon={icon}
                    title={title}
                    // titleProps={{ fontSize: 10 }}
                    color={color}
                    subtitle={dayjs(progress.waktu_verif).format(
                      "DD-MM-YYYY HH:mm:ss"
                      )}
                  />
                  );
              })}
            </Timeline>
          </Box>
          )}
      </Grid>
    </Grid>
  );
};

export default KadisPelayananPage;
