import { Button, Grid, Stack, Typography, Dialog, DialogContent, TextField, DialogContentText, DialogTitle, DialogActions, Divider, Card, CardMedia, CardActionArea, CardContent, Breadcrumbs, Link, Tooltip, IconButton } from "@mui/material";
import { ArrowBackTwoTone, CreateNewFolder, CreateNewFolderTwoTone, DeleteTwoTone, RemoveRedEyeTwoTone, UploadFile, UploadFileTwoTone } from "@mui/icons-material"
import React from "react";
import { useFormik } from "formik";
import HttpServices from "../../../utils/HttpServices";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import PdfView from "./PdfView";


const AdminEkita = () => {
    
    const [openDialogNewFolder, setOpenDialogNewFolder] = React.useState(false);
    const [openDialogNewFile, setOpenDialogNewFile] = React.useState(false);
    const [openDialogViewPdf, setOpenDialogViewPdf] = React.useState(false);
    const [level, setLevel] = React.useState(1)
    const [parent, setParent] = React.useState(null)
    const [folderName, setFolderName] = React.useState("")
    const [folderList, setFolderList] = React.useState([])
    const [folder_id, setFolder_id] = React.useState(null)
    const [breads, setBreads] = React.useState([])
    const [filename, setFilename] = React.useState(null)
    const [fileUrl, setFileUrl] = React.useState(null)

    const {enqueueSnackbar} = useSnackbar()

    function SuccessMsg(message){
        enqueueSnackbar(message, {
            variant: "success",
            persist: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
        });
    }

    function ErrorMsg(message){
        enqueueSnackbar(message, {
            variant: "error",
            persist: false,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
        });
    }
    

    function GetFolderLists(){
        HttpServices.get("/filemanager").then((response) => {
            setFolderList(response.data)
        }).catch(error => console.log(error))
    }

    function GetFolderByLevels(lv){
        HttpServices.get("/filemanager/level/"+lv).then((response) => {
            setFolderList(response.data)
        }).catch(error => console.log(error))
    }

    function GetAllFiles(f_id){
        HttpServices.get("/filemanager/id/"+f_id).then((response) => {
            setFolderList(response.data)
        }).catch(error => console.log(error))
    }
    
    function GetDetails(parent_id){
        HttpServices.get("/filemanager/details", {
            params: {
                parent_id: parent_id
            }
        }).then(response => setFolderList((response.data)))
    }
    
    function handleCreateFolder(){
        HttpServices.post("/filemanager", {
            folder_name: folderName,
            tree_level: level,
            parent_id: parent
        }).then((response) => {
            SuccessMsg("success")
            if(level > 1){
                GetDetails(parent)
                handleCloseNewFolder()
                setFolderName("")
            }else{
                GetFolderLists()
                handleCloseNewFolder()
                setFolderName("")
            }
        }).catch(error => {
            ErrorMsg("error !!")
        })
    }

    function handleCloseNewFolder(){
        setOpenDialogNewFolder(false)
    }

    function handleOpenNewFolder(){
        setOpenDialogNewFolder(true)
    }

    function handleCloseNewFile(){
        setOpenDialogNewFile(false)
    }

    function handleOpenNewFile(){
        setOpenDialogNewFile(true)
    }

    function handleOpenPdf(){
        setOpenDialogViewPdf(true)
    }

    function handleClosePdf(){
        setOpenDialogViewPdf(false)
    }

    function handleRemove(id, type){
        if(type === "folder"){
            HttpServices.post("/filemanager/folder/remove", {folder_id: id}).then((response) => {
                SuccessMsg("success")
                if(parent === null){
                    GetFolderLists()
                }else{
                    GetDetails(parent)
                }
            }).catch(error => {
                ErrorMsg("error !!")
            })
        }else{
            HttpServices.post("/filemanager/file/remove", {file_id: id}).then((response) => {
                SuccessMsg("success")
                if(parent === null){
                    GetFolderLists()
                }else{
                    GetDetails(parent)
                }
            }).catch(error => {
                ErrorMsg("error !!")
            })
        }
    }

    function handleUpload(){
        let formData = new FormData()
        
        console.log(filename)
        
        if(filename !== null){

            formData.append("file", filename)
            formData.append("folder_id", parent)

            HttpServices.post('/filemanager/ekita-bulanan/upload', formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }).then((response) => {
                if(level > 1){
                    GetDetails(parent)
                    SuccessMsg("success")
                    handleCloseNewFile()
                }else{
                    GetFolderLists()
                    handleCloseNewFile()
                    SuccessMsg("success")
                }
            }).catch(error => {
                ErrorMsg("error !!")
            })
        }
    }
    
    useEffect(() => {
        GetFolderLists()
    }, [])
    
    useEffect(() => {
        if(level <= 1){
            setParent(null)
        }
    }, [level])
    
    console.log("level", level)
    console.log("parent", parent)



    return (
        <React.Fragment>
            
            <Dialog open={openDialogNewFile} onClose={handleCloseNewFile}>
                    <DialogTitle>Upload File</DialogTitle>
                    <DialogContent>
                    <input type="file" onChange={(e) => setFilename(e.target.files[0])} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUpload}>Upload</Button>
                        <Button onClick={handleCloseNewFile}>Cancel</Button>
                    </DialogActions>
                
            </Dialog>
            <Dialog maxWidth="lg" fullWidth open={openDialogViewPdf} onClose={handleClosePdf}>
                    <DialogTitle>Preview</DialogTitle>
                    <DialogContent>
                        {fileUrl !== null && (
                            <PdfView filename={fileUrl} />
                        )}
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleUpload}>Upload</Button> */}
                        <Button onClick={handleClosePdf}>close</Button>
                    </DialogActions>
                
            </Dialog>
            <Dialog open={openDialogNewFolder} onClose={handleCloseNewFolder}>
                <DialogTitle>New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="folder_name"
                        value={folderName}
                        onChange={(e) => setFolderName((e.target.value))}
                        label="Folder Name"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateFolder}>Create</Button>
                    <Button onClick={handleCloseNewFolder}>Cancel</Button>
                </DialogActions>
                
            </Dialog>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                        <Button disabled={localStorage.getItem("role") !== "admin" && true} color="success" variant="outlined" startIcon={<CreateNewFolderTwoTone />} onClick={handleOpenNewFolder}>Create New Folder</Button>
                        <Button disabled={localStorage.getItem("role") !== "admin" && true} color="success" variant="outlined" startIcon={<UploadFileTwoTone />} onClick={handleOpenNewFile}>Upload File</Button>
                    </Stack>
                </Grid>
            </Grid>
            <Divider sx={{mt: 2, mb: 2}} />
            <Grid container spacing={1}>
                <Stack direction={"row"} spacing={1} justifyContent="center" justifyItems="baseline">
                {level > 1 && (
                    <Tooltip title="Kembali">
                        <IconButton onClick={() => {
                            GetFolderByLevels(level - 1)
                            setLevel(level - 1)
                        }}>
                            <ArrowBackTwoTone />
                        </IconButton>
                    </Tooltip>
                )}
                {/* <Breadcrumbs>
                {breads.map((b) => (
                        <Link underline="hover" color="InfoText">
                            {b}
                        </Link>
                ))}
                </Breadcrumbs> */}
                
                </Stack>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={3}>  
                        {folderList.map((folder) => (
                            <Card key={folder.folder_name ? folder.folder_name : folder.title} sx={{ maxWidth: 150 }}>
                            <Stack direction="row" divider={<Divider sx={{mt: 2, mb: 2}} variant="inset" />}>
                                {folder.filemanager_type==="FILE" && (
                                    <Tooltip title="Preview">
                                        <IconButton onClick={() => {
                                             setFileUrl(folder.filename)
                                             handleOpenPdf()
                                        }} color="primary">
                                            <RemoveRedEyeTwoTone />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title="Remove">
                                    <IconButton disabled={localStorage.getItem("role") !== "admin" && true} onClick={() => {
                                        if(folder.filemanager_type === "FILE"){
                                            handleRemove(folder.folder_id, "file")
                                        }else{
                                            handleRemove(folder.folder_id, "folder")
                                        }
                                    }} color="error">
                                        <DeleteTwoTone />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <CardActionArea  onDoubleClick={() => {
                                setParent(folder.folder_id)
                                setFolderName(folder.folder_name)
                                setBreads([...breads, folder.folder_name])
                                setLevel(folder.tree_level + 1)
//                                formik.setFieldValue("parent_id", folder.folder_id)
//                                formik.setFieldValue("tree_level", folder.tree_level + 1)
//                                GetFolderByLevels(level + 1)
//                                if(folder.files_dir && folder.files_dir.length > 0){
//                                    GetAllFiles(folder.folder_id)
//                                }
                                
                                GetDetails(folder.folder_id)
                            }}>
                              <CardMedia
                                component="img"
                                height="120"
                                image={`/static/images/${folder.filemanager_type === "FOLDER" ? 'folder128.png' : 'pdf.png'}`}
                                alt={folder.filemanager_type === "FOLDER" ? folder.folder_name : folder.title}
                              />
                              <CardContent>
                                <Typography variant="caption">
                                  {folder.filemanager_type === "FOLDER" ? folder.folder_name : folder.title}
                                </Typography>
                                
                                
                              </CardContent>
                             
                            </CardActionArea>
                            
                          </Card>
                        ))}
                    </Stack>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AdminEkita