import {
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  TextareaAutosize,
  Button,
  Box,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import React, { useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import HttpServices from "../../../utils/HttpServices";

const CutiAlasanPenting = () => {
  const { enqueueSnackbar } = useSnackbar();

  const today = dayjs();
  const tomorrow = dayjs().add(1, "day");
  const [tglMulai, setTglMulai] = React.useState(tomorrow);
  const [tglSampai, setTglSampai] = React.useState(null);
  const [detailPegawai, setDetailPegawai] = React.useState(null)
  const [allPegawai, setAllPegawai] = React.useState([])
  const [kadis, setkadis] = React.useState(null)
  const [sekretaris, setSekretaris] = React.useState(null)
  const [pemaraf, setPemaraf] = React.useState(null);
  const [ttd, setTtd] = React.useState(null) 
  const [opsi_persetujuan, setOpsi_persetujuan] = React.useState(1)

  const formik = useFormik({
    initialValues: {
      id_pegawai: parseInt(localStorage.getItem("id_pegawai")),
      jenis_cuti: "cuti_alasan_penting",
      alasan: "",
      alamat: "",
      id_pemberi_verifikasi: detailPegawai?.atasan?.id_pegawai,
      id_pemaraf: pemaraf,
      id_penanda_tangan: ttd,
      tgl_mulai: tomorrow,
      tgl_selesai: null,
    },
    onSubmit: (values) => {
      const mulai = dayjs(values.tgl_mulai);
      const selesai = dayjs(values.tgl_selesai);
      const durasi = selesai.diff(mulai, "day");

      HttpServices.post("/transaksi/pengajuan-cuti", {
        id_pegawai: values.id_pegawai,
        jenis_cuti: values.jenis_cuti,
        alasan: values.alasan,
        alamat: values.alamat,
        tgl_mulai: values.tgl_mulai,
        tgl_selesai: values.tgl_selesai,
        id_pemberi_verifikasi: detailPegawai?.atasan?.id_pegawai,
        id_pemaraf: pemaraf,
        id_penanda_tangan: ttd,
        durasi: durasi,
      })
        .then((response) => {
          enqueueSnackbar(
            "Pengajuan Draft Cuti Berhasil, Harap Menunggu Progress !!",
            {
              variant: "success",
              persist: false,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
          formik.resetForm();
        })
        .catch((error) => {
          enqueueSnackbar(
            "Pengajuan Draft Cuti Gagal, Harap Coba Beberapa Saat lagi !!",
            {
              variant: "error",
              persist: false,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        });
    },
  });

  const GetDetailPegawai = () => {
    HttpServices.get(`/master/pegawai/nip/${localStorage.getItem('nip')}`).then((response) => setDetailPegawai(response.data)).catch(error => console.log(error))
    // HttpServices.get(`/master/pegawai`).then((response) => setAllPegawai(response.data)).catch(error => console.log(error))
  }

  const GetKadis = () => {
    HttpServices.get(`/master/pegawai/jabatan/1`).then((response) => {
      if(opsi_persetujuan == 1){
        setkadis(response.data)
        setTtd(response.data.id_pegawai)
      }
    }).catch(error => console.log(error))
  }

  
  const GetSekretaris = () => {
    HttpServices.get(`/master/pegawai/jabatan/2`).then((response) => {
      if(opsi_persetujuan == 1){
        setSekretaris(response.data)
        setPemaraf(response.data.id_pegawai)
      }else{
        setTtd(response.data.id_pegawai)
        setPemaraf(null)
      }
    }).catch(error => console.log(error))
  }

  useEffect(() => {
    GetDetailPegawai()
  }, [])

  useEffect(() => {

    if(opsi_persetujuan == 1){
      GetKadis()
      GetSekretaris()
    }else if(opsi_persetujuan == 2){
      setkadis(null)
      GetSekretaris()
    }

  }, [opsi_persetujuan])


  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            // textAlign={"center"}
            component={"article"}
            variant="overline"
          >
            Form Pengajuan Cuti Alasan Penting
          </Typography>
          <Divider light />
          <Box component={"form"} noValidate onSubmit={formik.handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DatePicker", "DateTimePicker", "DateRangePicker"]}
              >
                <Stack direction={"row"} spacing={2}>
                  <DemoItem label="Cuti Mulai Tanggal">
                    <DatePicker
                      value={formik.values.tgl_mulai}
                      onChange={(v) => {
                        formik.setFieldValue("tgl_mulai", dayjs(v));
                      }}
                      defaultValue={dayjs(tomorrow).add(+2, "day")}
                      // minDate={dayjs(tomorrow).add(+2, "day")}
                      // maxDate={dayjs(formik.values.tgl_selesai).add(-1, "day")}
                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                  <DemoItem label="Cuti Sampai Tanggal">
                    <DatePicker
                      value={formik.values.tgl_selesai}
                      onChange={(v) => {
                        formik.setFieldValue("tgl_selesai", dayjs(v));
                      }}
                      defaultValue={dayjs(tomorrow).add(+2, "day")}
                      // minDate={dayjs(formik.values.tgl_mulai)}
                      views={["year", "month", "day"]}
                    />
                  </DemoItem>
                  <DemoItem label="Atasan Langsung">
                    <Typography variant="caption">{detailPegawai !== null ? `${detailPegawai.atasan.nama}` : ""}</Typography>
                    <Typography variant="caption">{detailPegawai !== null ? `${detailPegawai.atasan.nip}` : ""}</Typography>
                  {/* <TextField fullWidth disabled value={detailPegawai !== null ? `${detailPegawai.atasan.nip}-${detailPegawai.atasan.nama}` : ""} /> */}
                  <input hidden name="id_pemberi_verifikasi" onChange={formik.handleChange} value={detailPegawai !== null ? detailPegawai.atasan.id_pegawai : ""} />

                  </DemoItem>
                </Stack>
                <TextareaAutosize
                  value={formik.values.alamat}
                  name="alamat"
                  onChange={formik.handleChange}
                  minRows={5}
                  placeholder="Alamat Selama Menjalankan Cuti"
                />
                <TextareaAutosize
                  value={formik.values.alasan}
                  name="alasan"
                  onChange={formik.handleChange}
                  placeholder="Alasan Cuti"
                  minRows={5}
                />

                <FormControl>
                  <FormLabel>Opsi Persetujuan</FormLabel>
                  <RadioGroup value={opsi_persetujuan} name="opsi_persetujuan" onChange={(event) => setOpsi_persetujuan(event.target.value)}>
                    <Stack direction={"row"}>
                    <FormControlLabel value={1} control={<Radio />} label="Tanda Tangan Oleh Kepala Dinas" />
                      <FormControlLabel value={2} control={<Radio />} label="Tanda Tangan Oleh Sekretaris" />
                    </Stack>
                      
                  </RadioGroup>
                </FormControl>

                {}

                <Stack direction={"column-reverse"} spacing={1}>

                {opsi_persetujuan == 1 && (
                  <>
                    {kadis !== null && (
                    <DemoItem label="Penanda Tangan">
                      <TextField disabled value={kadis.nama} />
                      {/* <input name="id_penanda_tangan" onChange={(e) => setTtd(e.target.value)} value={kadis.id_pegawai} /> */}
                    </DemoItem>
                  )}
                

                  {sekretaris !== null && (
                     <DemoItem label="Pemaraf">
                     <TextField disabled value={sekretaris.nama} />
                     {/* <input name="id_pemaraf" onChange={(e) => setPemaraf(e.target.value)} value={sekretaris.id_pegawai} /> */}
                   </DemoItem>
                  )}
                  </>
                )}

                {opsi_persetujuan == 2 && (
                  <>
                  {sekretaris !== null && (
                     <DemoItem label="Penanda Tangan">
                     <TextField disabled value={sekretaris.nama} />
                     {/* <input hidden name="id_penanda_tangan" onChange={(e) => setTtd(e.target.value)} value={sekretaris.id_pegawai} /> */}
                   </DemoItem>
                  )}
                  </>
                )}
                </Stack>
                <Button type="submit" variant="contained" color="primary">
                  Ajukan Cuti Tahunan
                </Button>
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CutiAlasanPenting;
